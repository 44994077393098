<script>
import Vue from "vue"
import Gen from '../helper/Gen';

export default {
  props: ['value','config'],
  computed:{
    optionsPlugin(){
      var options = {
        preferredFormat: "hex",
        showAlpha: false,
        showPalette: false,
        chooseText: "Choose",
        palette: this.palette,
        cancelText: "cancel",
        change:(color)=>{
          this.$emit("input", color.toHexString())
        }
      }
      return Object.assign(options, this.config || {})
    }
  },
  data() {
    return {
      palette: [],
    }
  },
  async mounted(){
    await this.pluginInit()
    if(!this.value) this.$emit("input", "#000")
    $(this.$el).val(this.value).spectrum(this.optionsPlugin)
    /* Using palette */
    if(this.optionsPlugin.showPalette){
      Gen.apirest("/select-colors", {},(err,resp)=>{
        if(err) return;
        $(this.$el).spectrum("destroy");
        this.palette = resp.map(x=>{ return x.color })
        $(this.$el).val(this.value).spectrum(this.optionsPlugin)
      })
    }
  },
  methods:{
    async pluginInit(){
      Gen.loadCss("/plugins/spectrum/spectrum.css", 'top')
      await Gen.loadScript("/plugins/spectrum/spectrum.js")
    }
  },
  watch:{
    async value(v){
      await this.pluginInit()
      if(v) $(this.$el).spectrum("set", v)
    }
  }
}
</script>

<style lang="scss">
.sp-replacer{
  display: block;
  background: #fff;
  padding: 6px;
  .sp-preview{
    width: calc(100% - 18px);
  }
}
</style>

<template>
  <input type="text">
</template>